<template>
    <base-layout>
        <schedule-nav :user="user" />
        <guest-progress :show="showProgress" :value="progressPercent" />
        <dyna-table v-if="schedule.length > 0"
            tableClasses="table is-striped is-fullwidth"
            :headers="headers"
            :data="schedule"
            rowKey="PLANID"
            :supportMobile="true"
        />
      <p v-else-if="!showProgress" class="notification is-warning">
        No payment schedule records found for Plan ID '{{ $route.params.planId }}'
      </p>
    </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import ScheduleNav from "./ScheduleNav.vue";
import GuestProgress from "../commons/GuestProgress.vue";
import DynaTable from "@/components/DynaTable.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
    components: {
        BaseLayout,
        ScheduleNav,
        DynaTable,
        GuestProgress
    },
    props: ["user"],
    data() {
        return {
            showProgress: false,
            progressPercent: 0
        };
    },
    methods: {
      ...mapMutations("g", ["SET_DEMPORTALUSERNAME","SET_SCHEDULE"]),
      ...mapActions("g", ["fetchSchedule"])
    },
    computed: {
        ...mapState("g", ["schedule","demPortalUsername"]),
        headers() {
            if( this.schedule && this.schedule.length > 0) {
                return Object.values(this.schedule[0]).filter( s => s.label != 'PLANID' )
            }
            return [];
        }
    },
    beforeRouteEnter(to,from,next) {
        next( async vm => {
          try {
              vm.showProgress = true;
              vm.progressPercent = 10;

              vm.SET_SCHEDULE([]);

              const planId = vm.$route.params.planId;

              if( !vm.demPortalUsername ) {
                vm.SET_DEMPORTALUSERNAME(vm.user);
              }

              await vm.fetchSchedule(planId);
            } finally {
                vm.showProgress = false;
                vm.progressPercent = 0;
            }
        });
    }
};
</script>
<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <home-nav-list-item :user="user" />
            <li class="is-active">
                <router-link
                    :to="{ name: 'guest.schedule', params: { id: $route.params.id } }"
                >
                    <span class="icon is-small">
                    <font-awesome-icon icon="calendar" />
                    </span>
                    <span>Schedule</span></router-link
                >
            </li>
        </ul>
    </nav>
</template>
<script>
import HomeNavListItem from "../commons/HomeNavListItem.vue";

export default {
  components: { HomeNavListItem },
  props: ["user"]
};
</script>